import { Avatar, Box, Card, Typography } from "@mui/material";
import { IBoatBerthContract } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { FC } from "react";

interface BoatThumbnailProps {
  boat: IBoatBerthContract;
}

export const BoatThumbnail: FC<BoatThumbnailProps> = ({ boat }) => {
  return (
    <Card elevation={0} className="flex items-center my-2 p-3">
      <Avatar src={boat.picture} />
      <Box className="mx-3">
        <Typography variant="subtitle1">{boat.name}</Typography>
        {boat.length && boat.width && (
          <Typography variant="subtitle2">
            {boat.length} m x {boat.width} m
          </Typography>
        )}
        <Typography variant="subtitle2">{boat.type}</Typography>
      </Box>
    </Card>
  );
};
