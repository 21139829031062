import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { getFilteredNewsList } from "../../../../core/store/selectors/Module.selector";
import {
  changeSort,
  setSelected,
  updateFilter,
  updateFilterWithoutSaving
} from "../../../../core/store/reducers/News.reducer";
import { Box, Button, Checkbox, Chip, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { ModuleTable } from "../dashboard/Table";
import { TableTop } from "../dashboard/TableTop";
import dayjs from "dayjs";
import {
  deleteNews,
  getNewsList,
} from "../../../../core/store/actions/News.actions";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "../../../../_helpers/useConfirm";
import { AlertType, useAppContext } from "../../../contexts/AppContext";
import FilterListIcon from "@mui/icons-material/FilterList";
import { INews } from "@wattsonelements/front-fdk/dist/models/News.models";
import { UserPort } from "@wattsonelements/front-fdk/dist/models/User.models";

export const NewsTable: FC = () => {
  const { t } = useTranslation("news");
  const { confirm } = useConfirm();
  const { setAlertMessage } = useAppContext();
  // const news = useAppSelector((state) => state.news.list);
  const order = useAppSelector((state) => state.news.sort?.order);
  const orderBy = useAppSelector((state) => state.news.sort?.orderBy);
  const selectedNews = useAppSelector((state) => state.news.selected);
  const organization = useAppSelector((state) => state.users.profile?.organisation);
  const filters = useAppSelector((state) => state.news.filters);
  const loading = useAppSelector((state) => state.news.loading);
  const filteredNews = useAppSelector(getFilteredNewsList);
  const dispatch = useAppDispatch();

  const sortHandler = (key: string) => {
    let tmpOrder;
    if (order === "asc")
      tmpOrder = "desc";
    else
      tmpOrder = "asc";
    // if (order === undefined) {
    //   tmpOrder = "asc";
    // } else if (order === "asc") {
    //   tmpOrder = "desc";
    // } else {
    //   tmpOrder = undefined;
    // }
    dispatch(changeSort({ order: tmpOrder, orderBy: key }));
  };

  useEffect(() => {
    return () => {
      dispatch(updateFilterWithoutSaving({ text: "" }));
    }
  }, [])

  const stateFilterChanged = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    let tmpValues = typeof value === "string" ? value.split(",") : value;
    const newFilters = { ...filters, ports: [...tmpValues] };
    dispatch(updateFilter(newFilters));
  };

  const delNews = async (e: any, data: INews) => {
    e.preventDefault();
    e.stopPropagation();
    if (!(await confirm(t("confirmDelete")))) return;
    dispatch(deleteNews(data.id)).then(
      (e) => {
        if (e.type === "news/delete/rejected")
          setAlertMessage!({
            type: AlertType.ERROR,
            message: t("confirmError"),
          });
        else {
          setAlertMessage!({
            type: AlertType.SUCCESS,
            message: t("confirmDeleteFullfilled"),
          });
          dispatch(setSelected(null));
        }
      },
      (err) => {
        setAlertMessage!({ type: AlertType.ERROR, message: t("confirmError") });
      }
    );
    return false;
  };

  useEffect(() => {
    if (!loading && filteredNews.length === 0) {
      dispatch(getNewsList());
    }
    return () => {
      dispatch(setSelected(null));
    };
  }, []);

  const headers = [
    {
      label: t("table.headers.date"),
      key: "date_created",
      format: (data: INews) => {
        return dayjs(data.date_published).format(
          t("dates.full", { ns: "common" })
        );
      },
      sort: true,
      cellClassName: (data: INews) => {
        if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() > dayjs().unix() || !data.date_publication_end)) {
          return "cursor-pointer bg-fcosecondary bg-opacity-20";
        } else if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() < dayjs().unix())) {
          return "cursor-pointer bg-fcoerror bg-opacity-10";
        }
        else
          return "cursor-pointer"
      },
      compareValue: (data: INews) => dayjs(data.date_published).unix(),
    },
    {
      label: t("table.headers.title"),
      key: "subject",
      format: (data: INews) => {
        return data.subject;
      },
      sort: true,
      cellClassName: (data: INews) => {
        if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() > dayjs().unix() || !data.date_publication_end)) {
          return "cursor-pointer bg-fcosecondary bg-opacity-20";
        } else if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() < dayjs().unix())) {
          return "cursor-pointer bg-fcoerror bg-opacity-10";
        }
        else
          return "cursor-pointer"
      },
      compareValue: (data: INews) => data.subject,
    },
    {
      label: (organization && organization.ports.length > 1) ? t("form.ports") : "",
      key: "ports",
      format: (data: INews) => {
        if (data.ports && organization && organization.ports.length > 1) {
          let res = data.ports!.map((portId: string) => (organization.ports.find((port: UserPort) => port.id === portId) || { name: "" })!.name)
          return res.toString().replace(",", ", ")
        } else return ""
      },
      sort: false,
      cellClassName: (data: INews) => {
        if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() > dayjs().unix() || !data.date_publication_end)) {
          return "cursor-pointer bg-fcosecondary bg-opacity-20";
        } else if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() < dayjs().unix())) {
          return "cursor-pointer bg-fcoerror bg-opacity-10";
        }
        else
          return "cursor-pointer"
      },
      compareValue: () => 1,
    },
    {
      label: t("table.headers.delete"),
      key: "delete",
      format: (data: INews) => {
        return (
          <Button onClick={(e: any) => delNews(e, data)}>
            <DeleteIcon />
          </Button>
        );
      },
      sort: false,
      cellClassName: (data: INews) => {
        if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() > dayjs().unix() || !data.date_publication_end)) {
          return "bg-fcosecondary bg-opacity-20";
        } else if (dayjs(data.date_published).unix() <= dayjs().unix()
          && (dayjs(data.date_publication_end).unix() < dayjs().unix())) {
          return "bg-fcoerror bg-opacity-10";
        }
        else
          return ""
      },
      compareValue: () => 1,
    }
  ];

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <TableTop
            color="secondary"
            datas={filteredNews}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              dispatch(updateFilterWithoutSaving({ text: event.target.value }));
            }}
            buttons={
              (organization && organization.ports.length > 1) ?
                <>
                  <Box sx={{ minWidth: 110, maxWidth: 200 }}>
                    <FormControl size="small" fullWidth={true}>
                      {filters.ports.length < 1 && (
                        <InputLabel id="state-checkbox-label">
                          {t("form.ports")}
                        </InputLabel>
                      )}
                      <Select
                        labelId="state-checkbox-label"
                        id="state-checkbox"
                        multiple
                        value={filters.ports}
                        onChange={stateFilterChanged}
                        input={
                          <OutlinedInput
                          // label={t("form.ports")}
                          />
                        }
                        renderValue={(selected) =>
                          <Box className="flex flex-wrap gap-1 break-words">
                            {selected.map((id: string) =>
                              <span>{organization?.ports.find((port: UserPort) => port.id === id)
                                ?.name + ", "}</span>
                            )}
                          </Box>
                        }
                        IconComponent={FilterListIcon}
                      >
                        {organization?.ports.map((port: UserPort) => (
                          <MenuItem key={"port-" + port.id} value={port.id}>
                            <Checkbox checked={filters.ports.indexOf(port.id) > -1} />
                            <ListItemText
                              primary={port.name}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
                : <span></span>}
          />
          <ModuleTable
            rowPadding={true}
            selectedKey={selectedNews! ? selectedNews.id : undefined}
            selectable={false}
            onClickRow={(event: React.MouseEvent, data: INews) => {
              dispatch(setSelected(data));
            }}
            // onChangeSelection={setSelected}
            datas={filteredNews}
            headers={headers}
            order={order}
            orderBy={orderBy}
            onSort={sortHandler}
          />
        </>
      )}
    </>
  );
};
