import FDK from "@wattsonelements/front-fdk";
import { IMarinaBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncAutocomplete } from "../../../UX/AsyncAutocomplete";

interface TicketBerthInputProps {
  onChange: (berth: IMarinaBerth) => void;
  initialValue?: any;
  [key: string]: any;
}

export const TicketBerthInput: FC<TicketBerthInputProps> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const { t } = useTranslation("ticket");

  const [options, setOptions] = useState<IMarinaBerth[]>([]);

  const onSearch = (name: string) => {
    FDK.Marina.BerthModule.searchByBerthName(name).then((res) => {
      setOptions(res.data.results);
    });
  };

  return (
    <AsyncAutocomplete
      {...props}
      label={t("form.berth")}
      value={initialValue}
      onSearch={onSearch}
      onChange={onChange}
      options={options}
      optionsLabel={(option: IMarinaBerth) => option.name}
    />
  );
};
