import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { loadPaginatedDatas } from "../../../_helpers/loadPaginatedData.helpers";

export const getBerthsList = createAsyncThunk(
  "berth/list",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    return loadPaginatedDatas((page: number = 1) =>
      FDK.Marina.BerthModule.getBerthsList({ ...params, page })
    );
  }
);
export const getBerthDetail = createAsyncThunk(
  "berth/detail",
  async (id: UUID, { dispatch, getState }) => {
    return FDK.Marina.BerthModule.getBerthById(id).then((res) => res.data);
  }
);
export const getBerthStats = createAsyncThunk(
  "berth/stats",
  async (params: { port_area?: UUID }, { dispatch, getState }) => {
    return FDK.Marina.BerthModule.getBerthsStats(params).then((res) => res.data);
  }
);
