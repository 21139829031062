import { Badge, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";

export const GoToTicketBtn = (props: { ticketId: UUID, nbTickets?: number }) => {
  const navigate = useNavigate();
  const { areaId } = useParams()
  const { t } = useTranslation("common");
  const { ticketId, nbTickets = 0 } = props;

  return (
    <Badge badgeContent={nbTickets} color="primary">
      <Button
        color="light"
        variant="contained"
        startIcon={<VisibilityIcon />}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          // navigate(`/admin/area/${areaId || "all"}/modules/tickets/${ticketId}`);
          // no port areas for the moment
          navigate(`/admin/area/all/modules/tickets/${ticketId}`);
        }}
      >
        {t("buttons.ticket")}
      </Button>
    </Badge>
  );
};
