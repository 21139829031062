import { Box, LinearProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { UserAgentCard } from "../../elements/users/UserAgentCard.element";
import { useEffect } from "react";
import { getAgentsManagersList } from "../../../../core/store/actions/User.actions";
import { useTranslation } from "react-i18next";
import UserIcon from "../../../../assets/img/menu/UserIcon";
import { PageTitle } from "../../elements/PageTitle.element";

export const UsersCmsPage = () => {
  const { t } = useTranslation("users");
  const users = useAppSelector((state) => state.users.list);
  const loading = useAppSelector((state) => state.users.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && users.length === 0) {
      dispatch(getAgentsManagersList());
    }
  }, [users, loading]);
  return (
    <Box className="w-full h-full bg-white">
      <PageTitle title={t('title')} subtitle={t('subtitle')} icon={<UserIcon />} />
      {loading && <LinearProgress />}
      <Box className="flex flex-wrap gap-3 w-full p-8">
        {users.map((user, i) =>
          <UserAgentCard key={"user-" + i} user={user} />
        )}
      </Box>
    </Box>
  )
};
