import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { ELEC_STATUS } from "../../../../core/models/module.models";
import { usePeriodic } from "../../../../core/services/Periodic.service";
import {
  getElectricityList,
  getElectricityStats,
} from "../../../../core/store/actions/Electricity.action";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import {
  updateFilterWithoutSaving,
  setSelected as setSelectedPlug,
  updateFilter,
  setSelectedPowerUnit,
  setSelectedMarkerId,
  setFirstLoading,
} from "../../../../core/store/reducers/Electricity.reducer";
import { ElecMap } from "../../elements/dashboard/Electricity/ElecMap.element";
import { ElectricityChart } from "../../elements/dashboard/Electricity/ElecStats.element";
import { Legend } from "../../elements/dashboard/Legend";
import { DashboardLayout } from "../../layouts/Dashboard.layout";

import unpluged from "../../../../assets/img/icons/electricity/legend/plug-unpluged.svg"
import pluged from "../../../../assets/img/icons/electricity/legend/plug-pluged.svg"
import failure from "../../../../assets/img/icons/electricity/legend/plug-failure.svg"
import { formatAreaId } from "../../../../_helpers/misc.helpers";

export const ElectricityModulePage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("electricity");
  const { areaId } = useParams();
  const filters = useAppSelector((state) => state.electricity.filters);
  const delay = useAppSelector((state) => state.electricity.config.config.refresh_delay);
  const { startPeriodic, stopPeriodic, restartPeriodic } = usePeriodic();
  // TODO : TO CHECK with list


  useEffect(() => {
    dispatch(setFirstLoading(true))
    startPeriodic("elec-list", delay, () => dispatch(getElectricityList({ port_area: formatAreaId(areaId) })));
    startPeriodic("elec-stats", delay, () => dispatch(getElectricityStats({ port_area: formatAreaId(areaId) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(setSelectedPlug(null));
      stopPeriodic("elec-list");
      stopPeriodic("elec-stats");
      dispatch(updateFilterWithoutSaving({ text: "" }));
      dispatch(setSelectedMarkerId(null));
      dispatch(setSelectedPowerUnit(null));
    };
  }, [areaId]);

  const legend = [
    {
      legends: [
        // {
        //   label: (
        //     <div className="text-white bg-fcoerror rounded-full px-3 py-2">
        //       {t("legend.intensity")}
        //     </div>
        //   ),
        //   key: ELEC_STATUS.OVERCONSO,
        // },
        {
          label: t("legend.noUse"),
          key: ELEC_STATUS.NOT_IN_USE,
          icon: {
            src: unpluged,
          },
        },
        {
          label: t("legend.inUse"),
          key: ELEC_STATUS.IN_USE,
          icon: {
            src: pluged,
          },
        },
        {
          label: t("legend.failure"),
          key: ELEC_STATUS.FAILURE,
          icon: {
            src: failure,
          },
        },
      ],
    },
  ];

  
  return (
    <DashboardLayout
      top={
        <>
          <ElectricityChart />
          <Legend
            inline
            legendStatus={filters.status}
            updateLegend={(checked: boolean, key: string) => {
              const newFilters = {
                ...filters,
                status: { ...filters.status, [key]: checked },
              };
              dispatch(updateFilter(newFilters));
            }}
            legends={legend}
          />
        </>
      }
    >
      <Box className="electricity-content flex-col flex flex-1 max-w-[50%]" >
        <Outlet></Outlet>
      </Box>
      <ElecMap />
    </DashboardLayout>
  );
};
