import { createSlice } from "@reduxjs/toolkit";
import { authenthicate, requestNewPassword, resetPassword } from "../actions/Auth.actions";

export interface AuthState {
  isLoggedIn: boolean | 'pending';
  error: string;
  requestnewPasswordStatus: '' | 'loading' | 'error' | 'success'
  resetPasswordStatus: '' | 'loading' | 'error' | 'success'
  authStatus: '' | 'loading' | 'error' | 'success'
}

const initialState = {
  token: undefined,
  tokenRefresh: undefined,
  isLoggedIn: 'pending',
  error: "",
  authStatus: '',
  requestnewPasswordStatus: '',
  resetPasswordStatus: ''
} as AuthState;


export const Auth = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logoutAction: (state) => {
      state = { ...initialState, isLoggedIn: false }
      return state
    },
    refresh: (state, { payload }) => {
      state.isLoggedIn = true;
      return state
    },
    resetStatus: (state) => {
      state.requestnewPasswordStatus = ''
      state.resetPasswordStatus = ''
      return state
    },
    login: (state) => {
      state.isLoggedIn = true
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenthicate.pending, (state, { payload }) => {
        state.authStatus = 'loading'
      })
      .addCase(authenthicate.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true
        state.authStatus = 'success'
      })
      .addCase(authenthicate.rejected, (state, { payload }) => {
        state.authStatus = 'error'
      })
      .addCase(requestNewPassword.pending, (state, { payload }) => {
        state.requestnewPasswordStatus = 'loading'
      })
      .addCase(requestNewPassword.fulfilled, (state, { payload }) => {
        state.requestnewPasswordStatus = 'success'
      })
      .addCase(requestNewPassword.rejected, (state, { payload }) => {
        state.requestnewPasswordStatus = 'error'
      })
      .addCase(resetPassword.pending, (state, { payload }) => {
        state.resetPasswordStatus = 'loading'
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.resetPasswordStatus = 'success'
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.resetPasswordStatus = 'error'
      })
  }
})

export default Auth.reducer;
export const { logoutAction, resetStatus, refresh, login } = Auth.actions;