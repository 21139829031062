
import { IModuleListPaginated } from "@wattsonelements/front-fdk/dist/models/Module.models"
import { AxiosResponse } from "axios"


export const loadPaginatedDatas = async (request: (page?: number) => Promise<any>,
  initDatas: any[] = [], page = 1): Promise<any[]> => {
  try {
    const res = await request(page)
    const datas = res.data
    const updatedDatas = [...initDatas, ...datas.results]
    if (datas.next) {
      return await loadPaginatedDatas(request, updatedDatas, page + 1)
    } else {
      return Promise.resolve(updatedDatas)
    }
  } catch (e) {
    return Promise.reject()
  }

}

// const loadAllData = (apiRequest: (page?) => Observable<any>, dataSubject?: (datas?, end?) => void, initDatas = [], page = 1) {
//   return apiRequest(page).pipe(
//     tap(data => {
//       initDatas = [...initDatas, ...data.results];
//       dataSubject(initDatas, !!!data.next);
//     }),
//     takeWhile(data => !!data.next),
//     switchMap(() => {
//       return this.loadAllData(apiRequest, dataSubject, initDatas, ++page);
//     })
//   );
// }