import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as SVG } from './users.svg'

export const UserIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <SVG />
    </SvgIcon>
  );
}

export default UserIcon;