import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import bg from "../../../../assets/img/bg-login.jpg";
import logo from "../../../../assets/img/logo/round-logo.svg";
import whiteLogo from "../../../../assets/img/logo/logo-white.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { authenthicate } from "../../../../core/store/actions/Auth.actions";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../core/services/Auth.service";
import { AlertType, useAppContext } from "../../../contexts/AppContext";

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAppContext();
  const { t } = useTranslation("auth");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { authStatus } = useAppSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const submit = (data: any, event: any) => {
    event.preventDefault();
    if (!isValid || authStatus === "loading") {
      return;
    } else {
      dispatch(authenthicate(data)).then(
        (e) => {
          if (e.type === "auth/token/rejected")
            setAlertMessage!({
              type: AlertType.ERROR,
              message: t("login.fields.authError"),
            });
        },
        (err) => {
          setAlertMessage!({ type: AlertType.ERROR, message: t("login.fields.authError") });
        }
      );
    }
  };

  return (
    <Box
      className="p-0 m-0 w-full flex justify-center items-center flex-col relative"
      sx={{
        height: "100vh",
      }}
      component="main"
    >
      <img
        alt="boat"
        className="z-0 absolute w-full h-full object-cover object-center"
        src={bg}
      />
      <img className="z-10 w-72" src={whiteLogo} alt="logo white falco" />
      <Box className="z-10 mt-8 flex flex-col items-center justify-center w-72">
        <Card
          sx={{
            width: 300,
            backgroundColor: "#fff",
            paddingTop: 5,
            paddingBottom: 5,
          }}
          raised
        >
          <CardContent>
            <img alt="logo falco" className="h-20 m-auto" src={logo} />
            <Box
              component="form"
              onSubmit={handleSubmit(submit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("login.fields.email")}
                {...register("email", {
                  required: true,
                  pattern: new RegExp(
                    "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
                  ),
                })}
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                {...register("password", {
                  required: true,
                })}
                label={t("login.fields.password")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isValid}
              >
                {authStatus === "loading" ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <>{t("login.login")}</>
                )}
              </Button>
              <Box className="flex justify-center">
                <Link href="/forget-password" variant="body2">
                  {t("login.lostpassword")}
                </Link>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
