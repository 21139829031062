import { createSelector } from "@reduxjs/toolkit";
import { ITicketCategory } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { RootState } from "../store";

export const getCategoryById = createSelector(
  (state: RootState) => state.tickets.categories,
  (_: any, categoryId: string) => categoryId,
  (categories: ITicketCategory[], categoryId) => {
    return categories.find((cat) => cat.id === categoryId);
  }
);
