import { createSlice } from "@reduxjs/toolkit";
import { IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";
import { getUserProfile, updateUser, getAgentsManagersList } from "../actions/User.actions";

export interface UserState {
  profile: IUserMarina | undefined;
  list: IUserMarina[],
  loading: boolean,
  error: null | any,
}
const initialState = {
  profile: undefined,
  list: [],
  loading: false,
  error: null,

} as UserState;

export const Users = createSlice({
  name: "Users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state, { payload }) => { })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.profile = payload
      })
      .addCase(getUserProfile.rejected, (state, { payload }) => { })
      .addCase(updateUser.pending, (state, { payload }) => { })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.profile = payload
      })
      .addCase(updateUser.rejected, (state, { payload }) => { })
      .addCase(getAgentsManagersList.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getAgentsManagersList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = [...payload]
      })
      .addCase(getAgentsManagersList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
  }
})

export default Users.reducer;
// export const {  } = Auth.actions;