import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";
import { ReactComponent as SVG } from './reporting.svg'

export const ReportingIcon: FC = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <SVG />
    </SvgIcon>
  );
}

export default ReportingIcon;