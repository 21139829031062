import { Typography } from "@mui/material";
import { IDeparture } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import dayjs from "dayjs";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface BerthDepartureProps {
  departure: IDeparture;
}

export const BerthDeparture: FC<BerthDepartureProps> = ({ departure }) => {
  const { t } = useTranslation("berth");

  return (
    <Typography>
      <strong>{departure.motive}</strong> -{" "}
      {departure.date_start &&
        t("detail.from") +
          " " +
          dayjs(departure.date_start).format(
            t("dates.abbreviation_month_date", { ns: "common" })
          )}
      {departure.date_end &&
        " " +
          t("detail.to") +
          " " +
          dayjs(departure.date_end).format(
            t("dates.abbreviation_month_date", { ns: "common" })
          )}
    </Typography>
  );
};
