import { AlertType, useAppContext } from '../../contexts/AppContext';
import { Alert, Box, Button, Chip, Modal, TextField, Typography } from '@mui/material';
import { useQAContext } from '../../contexts/QAContext';
import { ChangeEvent, useState } from 'react';
import { useAuth } from '../../../core/services/Auth.service';
import { useAppSelector } from '../../../core/store/hooks';
import FDK from '@wattsonelements/front-fdk';

export const NarwhalModal = () => {
  const { logout } = useAuth();
  const { QAModalOpened, toggleQAModal, APP_VERSION, APP_ENV, API_URL, setAPIURL } = useQAContext();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [apiUrl, setApiUrl] = useState(API_URL);

  const apiUrlChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setApiUrl(e.target.value)
  }

  const changeAPIURL = () => {
    if (apiUrl !== API_URL) {
      setAPIURL!(apiUrl)
      if (isLoggedIn)
        logout();
      toggleQAModal!();
      FDK.MainAPI.updateAPIURL(apiUrl);
    }
  }
  const reset = () => {
    // local state
    setApiUrl(process.env.REACT_APP_API_URL || "")
    if (process.env.REACT_APP_API_URL !== API_URL) {
      // context state
      setAPIURL!(process.env.REACT_APP_API_URL || "")
      if (isLoggedIn)
        logout();
      toggleQAModal!();
      FDK.MainAPI.updateAPIURL(apiUrl);
    }
  }
  return (
    <Modal
      open={QAModalOpened}
      onClose={toggleQAModal}
    >
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 shadow-md w-auto md:w-96" sx={{
        bgcolor: "background.paper"
      }}>
        <h2 className="uppercase font-extrabold mb-2">Narwhal Utilities</h2>
        <Chip color="secondary" label={`Build version: ${APP_VERSION}`} />
        <Chip color="primary" label={`Build environment: ${APP_ENV}`} />
        <p className="text-xs p-0 m-0 mt-2 ">with an end backslash</p>
        <TextField
          margin="normal"
          required
          fullWidth
          onChange={apiUrlChanged}
          value={apiUrl}
          id="api_url"
          label="API_URL"
          autoFocus
        />
        <div className="flex gap-2 justify-end">
          <Button color="primary" variant="contained" onClick={changeAPIURL}>Change</Button>
          <Button color="error" variant="outlined" onClick={reset}>Reset</Button>
        </div>
      </Box>
    </Modal>)
}