import { Box } from "@mui/material";
import React from "react";

export const DashboardLayout = ({
  children,
  top,
}: {
  children: React.ReactNode;
  top?: React.ReactNode;
}) => {
  return (
    <Box className="dashboard-layout w-full flex flex-col flex-grow">
      {top && <Box className="dashboard-top flex w-full justify-center items-center bg-[white] py-2">
        {top}
      </Box>}
      <Box className="dashboard-content flex w-full flex-grow pb-4 grid-cols-2	">
        {children}
      </Box>
    </Box>
  );
};
