import { Box } from "@mui/system";
import PNG from "./electricity.png";

type ElectricityIconProps = {
  whiteSquare: boolean;
};

export const ElectricityIcon = (props: ElectricityIconProps) => {
  return !props.whiteSquare ? (
    <Box
      component="img"
      sx={{
        width: 10,
      }}
      alt="electricity icon"
      src={PNG}
    />
  ) : (
    <Box
      component="div"
      className="flex rounded-sm bg-white h-6 w-6 items-center justify-center"
    >
      <Box
        component="img"
        sx={{
          width: 10,
        }}
        alt="electricity icon"
        src={PNG}
      />
    </Box>
  );
};

export default ElectricityIcon;
