import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { IPlugDetail } from "@wattsonelements/front-fdk/dist/models/Electricity.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { loadPaginatedDatas } from "../../../_helpers/loadPaginatedData.helpers";

export const getElectricityList = createAsyncThunk(
  "electricity/list",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    return loadPaginatedDatas((page: number = 1) =>
      FDK.Marina.ElectricityModule.getPlugList({ ...params, page })
    );
  }
);

export const getElectricityStats = createAsyncThunk(
  "electricity/stats",
  async (params: { port_area?: UUID }, { rejectWithValue }) => {
    return FDK.Marina.ElectricityModule.getElectricityStats(params).then(
      (res) => res.data,
      (err) => rejectWithValue(err)
    );
  }
);

export const getPowerunitById = createAsyncThunk(
  "electricity/detail/powerunit",
  async (id: UUID, { dispatch, getState }) => {
    return FDK.Marina.ElectricityModule.getPowerunitDetail(id).then(
      (res) => res.data
    );
  }
);

const PLUG_MOCK = {
  in_use: false,
  name: "AP4.3-02",
  spot_name: "SEC 19",
  tickets: [],
  alerts: {
    failure: {
      // plug.active // à checker avec Keoma
      active: false,
    }, // prise répond pas / pas de données
  },
  berth: {
    name: "test",
  },
  global_consumption: {
    average: 0,
    now: 0,
    max_12_hours: 0,
    high_consumption_now: false,
    high_consumption_12_hours: false,
  },
  powerunit: {
    id: "0a6553c0-68dd-4f38-a9a3-943a7d5a91ed",
    name: "AP4.3",
  },
  contracts: [
    {
      name: "contract name",
      boat: {
        name: "bateau",
        length: 12,
        width: 4,
        type: "Voilier",
      },
      owner: {
        first_name: "prénom",
        last_name: "nom",
        phone: "0613645847",
        email: "mail@wefalco.fr",
      },
      date_start: new Date().toISOString(),
      date_finish: new Date().toISOString(),
    },
    {
      name: "contract name 2",
      boat: {
        name: "bateau",
        length: 12,
        width: 3,
        type: "Voilier",
      },
      owner: {
        first_name: "prénom",
        last_name: "nom",
        phone: "0613645847",
        email: "mail@wefalco.fr",
      },
      date_start: new Date().toISOString(),
      date_finish: new Date().toISOString(),
    },
  ],
  default_stat_slug: "6",
  stats: [
    {
      slug: "6",
      title: "Depuis 6h",
      cost: 12,
      currency: "€",
      url: "",
      average: 20,
      unit: "kWh",
    },
    {
      slug: "12",
      title: "Depuis 12h",
      cost: 15,
      currency: "€",
      url: "",
      average: 15,
      unit: "kWh",
    },
    {
      slug: "24",
      title: "Depuis 24h",
      cost: 280,
      currency: "€",
      url: "",
      average: 30,
      unit: "kWh",
    },
  ],
};
export const getPlugDetail = createAsyncThunk(
  "electricity/detail/plug",
  async (id: UUID, { rejectWithValue }) => {
    return FDK.Marina.ElectricityModule.getPlugById(id).then(
      (res) => {
        return { ...PLUG_MOCK, ...res.data } as IPlugDetail;
      },
      (err) => rejectWithValue(err)
    );
  }
);
