import { Avatar, Card, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Link } from "react-router-dom"
import EmailIcon from '@mui/icons-material/Email';
import { IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";

type UserCardProps = {
    user: IUserMarina
}

export const UserAgentCard = (props: UserCardProps) => {
    return (<div className="transform transition hover:scale-110 hover:z-10">
        <Card className="flex gap-x-2 p-3 items-center ">
            <Avatar sx={{ width: 60, height: 60 }} alt="Profile" src={props.user?.picture} />
            <Box className="flex flex-col justify-center">
                <Typography component="h1" variant="h6" className="font-bold leading-4">{props.user.first_name + " " + props.user.last_name}</Typography>
                <Box className="flex gap-x-2">
                    <a target="_blank" href={"mailto:" + props.user.email}>
                        <EmailIcon />
                    </a>
                    <a target="_blank" href={"mailto:" + props.user.email}>
                        <Typography className="">{props.user.email}</Typography>
                    </a>
                </Box>
            </Box>
        </Card>
    </div>)
}