import { Avatar, Box, Card, CardHeader, CircularProgress, Typography } from "@mui/material";
import { IUser } from "@wattsonelements/front-fdk/dist/models/User.models";
import { FC } from "react";

interface ContractuserProps {
  user: IUser;
}

export const ContractOwner: FC<ContractuserProps> = ({ user }) => {
  if (!user)
    return null
  return (
    <Card elevation={0} className="flex items-center my-2 p-3">
      <Avatar src={user.picture ? user.picture : ""} />
      <Box className="mx-3">
        <Typography variant="subtitle1">
          {user.first_name} {user.last_name}
        </Typography>
        <Typography variant="subtitle2">{user.email} </Typography>
        <Typography variant="subtitle2"> {user.phone}</Typography>
      </Box>
    </Card>
  );
};
