import { createSlice } from "@reduxjs/toolkit";
import { applyFiltersFromStorage, applyFiltersToStorage, FilterType } from "../../../_helpers/sort.helpers";
import { VigieState, VIGIE_STATUS } from "../../models/module.models";
import { getVigieList } from "../actions/Vigie.actions";


const initialState = applyFiltersFromStorage({
  list: [],
  sort: {
    order: undefined,
    orderBy: undefined
  },
  filters: {
    text: '',
    status: {
      [VIGIE_STATUS.UNPROTECTED]: true,
      [VIGIE_STATUS.FREEMIUM]: true,
      [VIGIE_STATUS.LOCKED]: true,
      [VIGIE_STATUS.UNLOCKED]: true
    }
  },
  selected: null,
  listStatus: '',
  legend: [],
  firstLoading: true,
  selectedStatus: '',
  config: {
    status: {
      available: true,
      last_update: new Date().toISOString(),
      error: {
        message: '',
        code: 200000
      }
    },
    config: {
      enable: true,
      refresh_delay: 10, // secondes
      reporting: true
    }
  }
}, FilterType.vigie) as VigieState;

export const Vigie = createSlice({
  name: "Vigie",
  initialState,
  reducers: {
    setFirstLoading: (state, { payload }) => {
      state.firstLoading = payload;
    },
    changeSort: (state, { payload }) => {
      state.sort = { ...state.sort, ...payload }
      applyFiltersToStorage<VigieState>(state, FilterType.vigie);
    },
    updateFilterWithoutSaving: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
      applyFiltersToStorage<VigieState>(state, FilterType.vigie);
    },
    updateStatus: (state, { payload }) => {
      state.filters.status = { ...state.filters.status, ...payload }
      applyFiltersToStorage<VigieState>(state, FilterType.vigie);
    },
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVigieList.pending, (state, { payload }) => { })
      .addCase(getVigieList.fulfilled, (state, { payload }) => {
        // TODO : back have to give us a true falco_level
        // we dont have to make this hack here
        let tmp: any = [];
        payload.map(p => tmp.push({ ...p, falco_level: p.devices.length < 1 ? "unprotected" : p.falco_level }))
        state.list = tmp;
        state.firstLoading = false
      })
      .addCase(getVigieList.rejected, (state, { payload }) => { })
  }
})

export default Vigie.reducer;
export const { updateFilter, updateStatus, setSelected, setFirstLoading, updateFilterWithoutSaving, changeSort } = Vigie.actions;