import { IMarinaVigie } from "@wattsonelements/front-fdk/dist/models/Vigie.models";

export const useVigieService = () => {
  const getVigieIconName = (berth: IMarinaVigie) => {};

  const generateVigieGeoJson = (datas: IMarinaVigie[]) => {
    let tmpGeoJson: any = {};
    tmpGeoJson = { type: "FeatureCollection", features: [] };
    datas.map((vigie, i) => {
      if (!("spots" in vigie) || !vigie.spots || vigie.spots.length === 0)
        return;
      if (!vigie.spots[0].longitude || !vigie.spots[0].latitude) return;
      const falcoLevel =
        vigie.devices.length === 0 ? "unprotected" : vigie.falco_level;
      const iconName =
        falcoLevel === "premium"
          ? vigie.locked
            ? "locked"
            : "unlocked"
          : falcoLevel;
      tmpGeoJson.features.push({
        type: "Feature",
        id: vigie.id,
        properties: {
          width: 20,
          icon: `${iconName}`,
          selectedIcon: `${iconName}-select`,
          anchor: { x: 10, y: 10 },
          clickable: true,
          id: vigie.id,
        },
        geometry: {
          type: "Point",
          coordinates: [vigie.spots[0].longitude, vigie.spots[0].latitude],
        },
      });
    });
    // console.log("vigie geojson", tmpGeoJson);

    return tmpGeoJson;
  };

  return { getVigieIconName, generateVigieGeoJson };
};
