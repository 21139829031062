import { Box, Button, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";

type TicketProps = {
  onClick?: () => void;
  create: boolean;
  count?: number;
  loading?: boolean;
};

export const TicketButton: FC<TicketProps> = ({
  onClick,
  create,
  count,
  loading = false,
}) => {
  const { t } = useTranslation("app");
  const { areaId } = useParams();
  const currentClass = "w-32 h-20 shadow-md rounded-t-md text-white flex flex-col font-bold justify-center items-center text-2xl "
  return (
    <Button onClick={onClick ? onClick : () => { }} sx={{ padding: 0 }}>
      {!create ?
        // no port areas for the moment
        // <NavLink to={`/admin/area/${areaId || "all"}/modules/tickets`}
        <NavLink to={`/admin/area/all/modules/tickets`}
          className={({ isActive }) => (isActive ? currentClass + "bg-fcomain hover:bg-fcomainhover" : currentClass + "bg-fcosecondary hover:bg-fcosecondaryhover")}
        >
          {loading ? <CircularProgress /> : <Box className="">{count}</Box>}
          <Box className="normal-case text-sm">{t('menu.modules.tickets')}</Box>
        </NavLink>
        :
        <Box className={currentClass + "bg-fcosecondary hover:bg-fcosecondaryhover"}>
          <Box className="">+</Box>
          <Box className="normal-case text-sm">{t('menu.modules.create')}</Box>
        </Box>
      }
    </Button >
  );
};
