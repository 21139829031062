import { createSlice } from "@reduxjs/toolkit";
import { INews } from "@wattsonelements/front-fdk/dist/models/News.models";
import { applyFiltersFromStorage, applyFiltersToStorage, FilterType } from "../../../_helpers/sort.helpers";
import { Order } from "../../models/module.models";
import { createNews, deleteNews, getNewsList, updateNews } from "../actions/News.actions";
import { getUserProfile, updateUser, getAgentsManagersList } from "../actions/User.actions";

export interface NewsState {
  selected: undefined | INews,
  list: INews[],
  loading: boolean,
  error: null | any,
  sort?: {
    order: Order,
    orderBy: string | undefined
  },
  filters: {
    text: string,
    ports: string[]
  }
}
const initialState = applyFiltersFromStorage({
  sort: {
    order: "desc",
    orderBy: "date_created"
  },
  filters: {
    text: '',
    ports: []
  },
  selected: undefined,
  list: [],
  loading: false,
  error: null,

}, FilterType.news) as NewsState;

export const News = createSlice({
  name: "News",
  initialState,
  reducers: {
    changeSort: (state, { payload }) => {
      state.sort = { ...state.sort, ...payload }
      applyFiltersToStorage<NewsState>(state, FilterType.news);
    },
    updateFilterWithoutSaving: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
      applyFiltersToStorage<NewsState>(state, FilterType.news);
    },
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsList.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getNewsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = [...payload]
      })
      .addCase(getNewsList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createNews.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createNews.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = [payload, ...state.list]
      })
      .addCase(createNews.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updateNews.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateNews.fulfilled, (state, { payload }) => {
        const tmp = [...state.list]
        const index = tmp.findIndex(t => t.id === payload.id)
        tmp[index] = payload
        state.list = tmp
        state.loading = false;
      })
      .addCase(updateNews.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteNews.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        const tmp = [...state.list]
        const index = tmp.findIndex(t => t.id === action.meta.arg)
        tmp.splice(index, 1)
        state.list = tmp
        state.loading = false;
      })
      .addCase(deleteNews.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
  }
})

export default News.reducer;
export const { updateFilter, setSelected, updateFilterWithoutSaving, changeSort } = News.actions;