import { Box, Button, Card, Chip, Paper, Typography } from "@mui/material"
import { IPlugContract } from "@wattsonelements/front-fdk/dist/models/Electricity.models"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { ContractDates } from "../../ContractDates.element"

type PlugContractProps = {
  contract: IPlugContract
}
export const PlugContract: FC<PlugContractProps> = ({ contract }) => {
  const { t } = useTranslation("electricity");

  return (
    <Card variant="outlined" className="flex gap-5 p-3 my-2">
     
      <Box className="flex-1 min-w-[150px]">
      
        <Box className="flex justify-between  mb-3">
          <Typography sx={{lineHeight:"1rem"}} component="p" variant="subtitle1">
            {contract.name}  
          </Typography>
          <Chip  color="primary" label={
            "holder" in contract && contract?.holder
              ? t("detail.contractType.annual")
              : t("detail.contractType.escale")
          } />
        </Box>
        
        <ContractDates contract={contract} />

      </Box>

      {contract.owner &&
        <Box className="flex-1 flex flex-col min-w-[150px]">
          <p className=" font-bold text-lg">{contract?.owner?.first_name} {contract?.owner?.last_name}</p>
          <a href={`mailto:${contract?.owner?.email}`}>{contract.owner.email}</a>
          <a href={`tel:${contract.owner.phone}`}>{contract.owner.phone}</a>
          {contract?.owner?.email && (
            <Button
              href={`mailto:${contract?.owner?.email}`}
              className="mx-auto mt-auto"
              startIcon={<MailOutlineIcon />}
              variant="outlined"
            >
              {t("buttons.contact", { ns: "common" })}
            </Button>
        )}
        </Box>
      }
      <Box className="flex-1 min-w-[150px]">
        {contract.boat &&
          <>
            <p className=" font-bold text-lg"> {contract.boat.name}</p>
            {contract.boat.type} - {contract.boat.length} x {contract.boat.length}
          </>
        }
      </Box>
    </Card>
  )
}