import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { loadPaginatedDatas } from "../../../_helpers/loadPaginatedData.helpers";

export const getVigieList = createAsyncThunk(
  "vigie/list",
  async (
    params: { page?: number; port_area?: UUID },
    { dispatch, getState }
  ) => {
    return loadPaginatedDatas((page: number = 1) =>
      FDK.Marina.VigieModule.getVigieList({ ...params, page })
    );
  }
);
