import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { INews } from "@wattsonelements/front-fdk/dist/models/News.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import dayjs from "dayjs";

export const getNewsList = createAsyncThunk("news/list",
  async (args, { dispatch, getState }) => {
    return FDK.Marina.NewsModule.getNewsList().then(res => {
      let tmp = res.data;
      tmp.sort((a, b) => dayjs(b.date_published).unix() - dayjs(a.date_published).unix())
      return tmp
    })
  }
)

export const getNewsDetail = createAsyncThunk("news/detail",
  async (id: UUID, { dispatch, getState }) => {
    return FDK.Marina.NewsModule.getNewsDetail(id).then(res => res.data)
  }
)

export const createNews = createAsyncThunk("news/create",
  async (news: FormData | Partial<INews>, { dispatch, getState }) => {
    return FDK.Marina.NewsModule.createNews(news).then(res => res.data)
  }
)

export const updateNews = createAsyncThunk("news/update",
  async ({ id, news }: { id: string, news: FormData | Partial<INews> }, { dispatch, getState }) => {
    return FDK.Marina.NewsModule.updateNews(id, news).then(res => res.data)
  }
)

export const deleteNews = createAsyncThunk("news/delete",
  async (id: string, { dispatch, getState }) => {
    return FDK.Marina.NewsModule.deleteNews(id).then(res => res.data)
  }
)