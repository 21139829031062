import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { IUserMarina } from "@wattsonelements/front-fdk/dist/models/User.models";
import { FC } from "react";

interface UserslistProps {
  users: IUserMarina[];
  max?: number;
}

export const Userslist: FC<UserslistProps> = ({ users, max = 4 }) => {
  return (
    <AvatarGroup spacing={18} max={max}>
      {users.map((u) => (
        <Tooltip
          key={`user-tooltip-${u.id}`}
          placement="top"
          title={u.first_name + " " + u.last_name}
        >
          <Avatar key={u.id} src={u.picture} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};
