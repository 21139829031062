import { LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system"
import { IContractBerth } from "@wattsonelements/front-fdk/dist/models/Berth.models";
import { IPlugContract } from "@wattsonelements/front-fdk/dist/models/Electricity.models";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type ContractDatesProps = {
  contract: IContractBerth | IPlugContract
}

export const ContractDates:FC<ContractDatesProps> = ({contract})=> {
  const [slider, setSlider] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);
  const { t } = useTranslation("berth");


  useEffect(() => {
    if (!contract || !contract?.date_finish || !contract.date_start) return;
    const startContract = dayjs(contract.date_start);
    const finishContract = dayjs(contract?.date_finish);
    let contractDuration = Math.abs(startContract.diff(finishContract, "day", true));
    let daysLeft = Math.abs(dayjs().diff(finishContract, "day", true));

    setSlider(((contractDuration - daysLeft) * 100) / contractDuration);
    setDaysLeft(Math.round(daysLeft * 10) / 10);
  }, [contract]);
  if(!contract.date_start || !contract.date_finish) {
    return <></>
  }
  return (
    <Box>
            <Box className="flex justify-between">
              <p>
                {dayjs(contract.date_start).format(
                  t("dates.abbreviation_month_date", { ns: "common" })
                )}
              </p>
              <p>
                {dayjs(contract.date_finish).format(
                  t("dates.abbreviation_month_date", { ns: "common" })
                )}
              </p>
            </Box>
            <LinearProgress
              color="secondary"
              variant="determinate"
              value={slider}
            />
            <Typography align="right">
              {daysLeft} {t("detail.daysLeft", { s: daysLeft < 2 ? "" : "s" })}
            </Typography>
          </Box>
  )
}