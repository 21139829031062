import { MapTwoTone } from "@mui/icons-material";
import { loadImage } from "./image.helpers";
import FDK from "@wattsonelements/front-fdk";
import { useAppSelector } from "../core/store/hooks";
import { IPort } from "@wattsonelements/front-fdk/dist/models/Port.models";

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export enum MapType {
  VIGIE,
  BERTH,
  ELECTRICITY,
  TICKET,
}

function createCircleSVG(nb: number) {
  let tmpTextOk =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><g> \
    <circle stroke-width="0" fill="COLOR" stroke="#000000" cx="250" cy="250" r="245"/> \
    <text x="50%" font-size="20em" y="50%" text-anchor="middle" fill="#000000" stroke="#000000" stroke-width="2px" dy=".3em">👍</text> \
    </g></svg>';
  let tmpTextKo =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><g> \
    <circle stroke-width="0" fill="COLOR" stroke="#000000" cx="250" cy="250" r="245"/> \
    <text font-weight="bold" font-family="Arial, Helvetica, sans-serif" x="50%" font-size="15em" y="50%" \
    text-anchor="middle" fill="#000000" stroke="#000000" stroke-width="2px" dy=".3em">VALUE</text> \
    </g></svg>';
  let tmpText = nb > 0 ? tmpTextKo.replace("VALUE", nb.toString()) :tmpTextOk ;
  tmpText = tmpText.replace("COLOR", nb > 0 ? "#fb8282" : "#d8ffd8");
  tmpText = unescape(encodeURIComponent(tmpText));
  tmpText = btoa(tmpText);
  return `data:image/svg+xml;base64,${tmpText}`;
}

const getBerthIcons = async (port: IPort) => {
  const statusImg: any = {};
  for (const portArea of port.areas) {
    try {
      statusImg[`port_area-${MapType.BERTH}-${portArea.id}`] = await loadImage(
        createCircleSVG(portArea.stats.berth.hazard[0]),
        [2000, 2000]
      );
    } catch (e) {
    }
  }
  for (const status of enumKeys(FDK.Constants.BerthConstants.BERTH_STATUS)) {
    if (
      FDK.Constants.BerthConstants.BERTH_STATUS[status].match("mooring") !==
      null
    ) {
      try {
        statusImg[FDK.Constants.BerthConstants.BERTH_STATUS[status]] =
          await loadImage(
            require(`../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS[status]}.svg`),
            [187, 462]
          );
        statusImg[
          FDK.Constants.BerthConstants.BERTH_STATUS[status] + "-select"
        ] = await loadImage(
          require(`../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS[status]}-select.svg`),
          [303, 600]
        );
      } catch (e) {}
    } else {
      try {
        statusImg[FDK.Constants.BerthConstants.BERTH_STATUS[status]] =
          await loadImage(
            require(`../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS[status]}.svg`),
            [165, 408]
          );
        // statusImg[FDK.Constants.BerthConstants.BERTH_STATUS[status] + "-select"] = await loadImage(require(`../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS[status]}-select.svg`), [187, 462]);
        statusImg[
          FDK.Constants.BerthConstants.BERTH_STATUS[status] + "-select"
        ] = await loadImage(
          require(`../assets/img/icons/berth/map/v2/${FDK.Constants.BerthConstants.BERTH_STATUS[status]}-select.svg`),
          [303, 600]
        );

        statusImg["buoy-" + FDK.Constants.BerthConstants.BERTH_STATUS[status]] =
          await loadImage(
            require(`../assets/img/icons/berth/map/v2/buoy-${FDK.Constants.BerthConstants.BERTH_STATUS[status]}.svg`),
            [163, 176]
          );
        statusImg[
          "buoy-" +
            FDK.Constants.BerthConstants.BERTH_STATUS[status] +
            "-select"
        ] = await loadImage(
          require(`../assets/img/icons/berth/map/v2/buoy-${FDK.Constants.BerthConstants.BERTH_STATUS[status]}-select.svg`),
          [260, 260]
        );
      } catch (e) {}
    }
  }
  return statusImg;
};

const getElecIcons = async (port: IPort) => {
  const statusImg: any = {};

  let puFailure = "pu-failure";
  let plugFailure = "plug-failure";
  let puNoError = "pu-no-error";
  let puSelect = "pu-select";
  let plugUnpluged = "plug-unpluged";
  let plugPluged = "plug-pluged";
  let plugSelect = "plug-select";
  let plugAlert = "plug-alert";


  for (const portArea of port.areas) {
    try {
      statusImg[`port_area-${MapType.ELECTRICITY}-${portArea.id}`] = await loadImage(
        createCircleSVG(portArea.stats.electricity.hazard[0]),
        [2000, 2000]
      );
    } catch (e) {
    }
  }

  // plug icon TODO SVG / ENUM - can be reused with list filter status maybe ?
  try {
    statusImg["plug-pluged"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${plugPluged}.svg`),
      [250, 250]
    );
    statusImg["plug-select"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${plugSelect}.svg`),
      [250, 250]
    );

    statusImg["plug-unpluged"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${plugUnpluged}.svg`),
      [250, 250]
    );
    statusImg["plug-failure"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${plugFailure}.svg`),
      [250, 250]
    );
    statusImg["plug-alert"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${plugAlert}.svg`),
      [250, 250]
    );

    statusImg["pu-no-error"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${puNoError}.svg`),
      [350, 350]
    );
    statusImg["pu-select"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${puSelect}.svg`),
      [350, 350]
    );
    statusImg["pu-failure"] = await loadImage(
      require(`../assets/img/icons/electricity/map/v2/${puFailure}.svg`),
      [350, 350]
    );
  } catch (e) {
    console.error("error img map elec", e);
  }
  return statusImg;
};

const getTicketIcons = async (port: IPort) => {
  const statusImg: any = {};

  // if not declared here, IT DOES NOT WORK !!! WHYYYYY ?? (not a `` or "" issue) :elmo: 💣🔥🔥🔥
  let emergency = "emergency";
  let incident = "incident";
  let selected_ticket = "selected_ticket";

  for (const portArea of port.areas) {
    try {
      statusImg[`port_area-${MapType.TICKET}-${portArea.id}`] = await loadImage(
        createCircleSVG(portArea.stats.ticket.hazard[0]),
        [2000, 2000]
      );
    } catch (e) {
    }
  }
  try {
    statusImg["emergency"] = await loadImage(
      require(`../assets/img/icons/tickets/map/${emergency}.svg`),
      [300, 300]
    );
    statusImg["incident"] = await loadImage(
      require(`../assets/img/icons/tickets/map/${incident}.svg`),
      [300, 300]
    );
    statusImg["selected_ticket"] = await loadImage(
      require(`../assets/img/icons/tickets/map/${selected_ticket}.svg`),
      [300, 300]
    );
  } catch (e) {
    console.log("error img map ticket", e);
  }
  // console.log(statusImg);
  return statusImg;
};

const getVigieIcons = async (port: IPort) => {
  const statusImg: any = {};

  // TODO DECLARE HERE LIKE TICKETS NAMES
  let locked = "locked";
  let unlocked = "unlocked";
  let unprotected = "unprotected";
  let premium = "premium";
  let freemium = "freemium";
  for (const portArea of port.areas) {
    try {
      statusImg[`port_area-${MapType.VIGIE}-${portArea.id}`] = await loadImage(
        createCircleSVG(portArea.stats.vigie.hazard[0]),
        [2000, 2000]
      );
    } catch (e) {
    }
  }
  try {
    statusImg["locked"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${locked}.svg`),
      [300, 300]
    );
    statusImg["locked-select"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${locked}-select.svg`),
      [300, 300]
    );
    statusImg["unlocked"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${unlocked}.svg`),
      [300, 300]
    );
    statusImg["unlocked-select"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${unlocked}-select.svg`),
      [300, 300]
    );
    statusImg["freemium"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${freemium}.svg`),
      [300, 300]
    );
    statusImg["freemium-select"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${freemium}-select.svg`),
      [300, 300]
    );
    statusImg["premium"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${premium}.svg`),
      [300, 300]
    );
    statusImg["unprotected"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${unprotected}.svg`),
      [300, 300]
    );
    statusImg["unprotected-select"] = await loadImage(
      require(`../assets/img/icons/vigie/map/v2/${unprotected}-select.svg`),
      [300, 300]
    );
  } catch (e) {
    console.log("error img map ticket", e);
  }
  return statusImg;
};

export const getMapIcons = async (mapType: MapType, port: IPort) => {
  let statusImg: any = {};
  switch (mapType) {
    case MapType.BERTH:
      statusImg = await getBerthIcons(port);
      break;
    case MapType.ELECTRICITY:
      statusImg = await getElecIcons(port);
      break;
    case MapType.TICKET:
      statusImg = await getTicketIcons(port);
      break;
    case MapType.VIGIE:
      statusImg = await getVigieIcons(port);
      break;

    default:
      break;
  }
  return statusImg;
};
