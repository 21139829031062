import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";
import { ReactComponent as HomeSVG } from './home.svg'

export const HomeIcon: FC = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <HomeSVG />
    </SvgIcon>
  );
}

export default HomeIcon;