import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import bg from "../../../../assets/img/bg-reset-password.jpg";
import logo from "../../../../assets/img/logo/round-logo.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { Alert, CircularProgress } from "@mui/material";
import { requestNewPassword } from "../../../../core/store/actions/Auth.actions";
import { useForm } from "react-hook-form";

export const LostPasswordPage = () => {
  const dispatch = useAppDispatch();
  const { requestnewPasswordStatus } = useAppSelector((state) => state.auth);
  const { t } = useTranslation("auth");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });

  const submit = (data: any, event: any) => {
    event.preventDefault();
    if (!isValid || requestnewPasswordStatus === "loading") {
      return;
    } else {
      dispatch(requestNewPassword(data));
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        className="flex-col flex justify-center items-center"
      >
        <Box
          className="max-w-sm"
          sx={{
            my: 8,
            mx: 4,
          }}
        >
          <img alt="logo falco" className="h-20 m-auto mb-6" src={logo} />
          <Grid className="mb-5" container>
            <Grid item xs>
              <Link href="/connexion" variant="body2">
                <ArrowBackIcon />
                {t("lostpassword.backbtn")}
              </Link>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h5">
            {t("lostpassword.title")}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {t("lostpassword.subtitle")}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label={t("lostpassword.fields.placeholder")}
              autoComplete="email"
              autoFocus
              helperText={!!errors.email && t("lostpassword.fields.emailerror")}
              error={!!errors.email}
              {...register("email", {
                required: true,
                pattern: new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
              })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValid}
            >
              {requestnewPasswordStatus === "loading" ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <>{t("lostpassword.btn")}</>
              )}
            </Button>
            {requestnewPasswordStatus === "error" && (
              <Alert severity="error">
                {t("lostpassword.fields.errorMsg")}
              </Alert>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          position: "relative",
        }}
      >
        <img
          alt="boat"
          className="z-0 absolute w-full h-full object-cover object-center"
          src={bg}
        />
      </Grid>
    </Grid>
  );
};
