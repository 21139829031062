import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";


export const authenthicate = createAsyncThunk(
  "auth/token",
  async (
    { email, password }: { email: string; password: string },
    { dispatch, getState }
  ) => {
    return FDK.Auth.sendEmailCredentials(email, password).then((res) => {
      localStorage.setItem("access", res.data.access);
      localStorage.setItem("refresh", res.data.refresh);
      return res.data;
    });
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset",
  async (
    { password, token }: { password: string; token: string },
    { dispatch, getState }
  ) => {
    return FDK.Auth.updatePassword(password, token).then((res) => {
      return res.data;
    });
  }
);

export const requestNewPassword = createAsyncThunk(
  "auth/lostPassword",
  async ({ email }: { email: string }, { dispatch, getState }) => {
    return FDK.Auth.sendResetPasswordRequest(email).then((res) => {
      return res.data;
    });
  }
);
