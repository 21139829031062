import useConfirm from '../../../_helpers/useConfirm';
import { createPortal } from 'react-dom';
import { AlertType } from '../../contexts/AppContext';
import { Alert, Box, Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ConfirmModal = () => {
  const {t} = useTranslation("common");
  const { onConfirm, onCancel, alertMessage } = useConfirm();
  const portalElement = document.getElementById('portal') as HTMLElement;
  const component = (

    <Modal
      open={alertMessage.type === AlertType.CONFIRM && alertMessage.message !== ""}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 shadow-md w-auto md:w-96" sx={{
        bgcolor: "background.paper"
      }}>
        <div className="p-4 gap-y-2">
          {/* <div className="flex items-center w-full pb-5">
            <div className="font-medium text-lg w-full">{t("modals.confirm.title")}</div>
            <CloseIcon className="ml-auto fill-current hover:opacity-70 w-6 h-6 cursor-pointer" onClick={onCancel} />
          </div> */}
          <Alert severity="warning">{alertMessage.message}</Alert>
          <div className="w-full flex justify-end text-center gap-x-2 pt-5">
            <Button variant="contained" onClick={onConfirm} className="apbuttonaction-blue">{t("buttons.validate")}</Button>
            <Button variant="outlined" color="error" onClick={onCancel} className="apbutton-pink">{t("buttons.cancel")}</Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
  return createPortal(component, portalElement);
}