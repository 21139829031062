import { BerthState } from "../core/models/module.models";
import jwt_decode, { JwtPayload } from "jwt-decode";

export const compareString = (str: string, words: string) => {
  return str
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .includes(
      words
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLocaleLowerCase()
    );
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stringDescendingComparator<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

interface JwtCustom extends JwtPayload {
  user_id: string;
}

export enum FilterType {
  berth = "berth",
  vigie = "vigie",
  ticket = "ticket",
  electricity = "electricity",
  news = "news",
}

export function applyFiltersFromStorage<T>(state: T, type: FilterType) {
  // get the user id
  const refresh = localStorage.getItem("refresh");
  let decoded: any = refresh && jwt_decode<JwtCustom>(refresh || "");
  if (
    refresh === null ||
    decoded === "" ||
    decoded === null ||
    decoded === undefined
  )
    return state;
  // check if filters exist
  let localFilters = localStorage.getItem("marina-filters-" + decoded.user_id);
  if (localFilters === null) return state;
  let filters = JSON.parse(localFilters);
  if (!filters[type]) return state;
  let finalState = { ...state } as any;
  return {
    ...finalState,
    filters: { ...finalState.filters, ...filters![type].filters },
    sort: { ...finalState.sort, ...filters![type].sort },
  };
}

export function applyFiltersToStorage<T>(state: any, type: FilterType) {
  // get the user id
  const refresh = localStorage.getItem("refresh");
  let decoded: any = refresh && jwt_decode<JwtCustom>(refresh || "");
  if (refresh === null || decoded === "" || decoded === null) return -1;

  // check if filters exist
  let localFilters = localStorage.getItem("marina-filters-" + decoded.user_id);
  let filters: any = {};
  filters[type] = { filters: { ...state.filters }, sort: { ...state.sort } };
  if (localFilters !== null) {
    let oldFilters = JSON.parse(localFilters);
    filters = { ...oldFilters };
    filters[type] = {
      filters: { ...oldFilters.filters, ...state.filters },
      sort: { ...oldFilters.sort, ...state.sort },
    };
  }
  localStorage.setItem(
    "marina-filters-" + decoded.user_id,
    JSON.stringify(filters)
  );
  return 1;
}
