import { useAppDispatch, useAppSelector } from "../../../../core/store/hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../../core/store/actions/Auth.actions";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import bg from "../../../../assets/img/bg-reset-password.jpg";
import logo from "../../../../assets/img/logo/round-logo.svg";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const ResetPasswordPage = () => {
  // wiht token in url
  const dispatch = useAppDispatch();
  const { resetPasswordStatus } = useAppSelector((state) => state.auth);
  // const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   // if (!searchParams.get("token") || !searchParams.get("email")) {
  //   //   // redirection vers connexion
  //   // }
  // }, [searchParams]);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });

  const submit = (data: any, event: any) => {
    event.preventDefault();
    // const token = searchParams.get("token");
    const token = "temp waiting for router";
    if (!isValid || !token || resetPasswordStatus === "loading") {
      return;
    } else {
      dispatch(
        resetPassword({
          password: data.password,
          token,
        })
      );
    }
  };

  const { t } = useTranslation("auth");

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        className="flex-col flex justify-center items-center"
      >
        <Box
          className="max-w-sm"
          sx={{
            my: 8,
            mx: 4,
          }}
        >
          <img alt="logo falco" className="h-20 m-auto mb-6" src={logo} />
          <Grid className="mb-5" container>
            <Grid item xs>
              <Link href="/connexion" variant="body2">
                <ArrowBackIcon />
                {t("resetPassword.backbtn")}
              </Link>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h5">
            {t("resetPassword.title")}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {t("resetPassword.subtitle")}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              type="password"
              label={t("resetPassword.fields.newPwdPlaceholder")}
              helperText={!!errors.password && errors.password.message}
              error={!!errors.email}
              {...register("password", {
                required: true,
                minLength: {
                  value: 8,
                  message: t("resetPassword.fields.errorLength"),
                },
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              type="password"
              label={t("resetPassword.fields.confirmPwdPlaceholder")}
              helperText={
                !!errors.confirmPassword && errors.confirmPassword.message
              }
              error={!!errors.confirmPassword}
              {...register("confirmPassword", {
                validate: (val: string) => {
                  if (watch("password") !== val) {
                    return t("resetPassword.fields.errorMustMatch");
                  }
                },
                required: true,
              })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValid}
            >
              {resetPasswordStatus === "loading" ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <>{t("resetPassword.btn")}</>
              )}
            </Button>
            {resetPasswordStatus === "error" && (
              <Alert severity="error">
                {t("resetPassword.fields.errorMsg")}
              </Alert>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          position: "relative",
        }}
      >
        <img
          alt="boat"
          className="z-0 absolute w-full h-full object-cover object-center"
          src={bg}
        />
      </Grid>
    </Grid>
  );
};
