import { SvgIcon, SvgIconProps } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import PNG from './tickets.png'

type TicketIconProps = {
  // SvgProps?: SvgIconProps,
  whiteSquare: boolean
}

export const TicketIcon = (props: TicketIconProps) => {

  return !props.whiteSquare ?
    (
      <Box
        component="img"
        sx={{
          width: 17
        }}
        alt="ticket icon"
        src={PNG}
      />
    ) :
    (
      <Box component="div" className="flex rounded-sm bg-white h-6 w-6 items-center justify-center">
        <Box
          component="img"
          sx={{
            width: 17
          }}
          alt="ticket icon"
          src={PNG}
        />
      </Box>
    );
}

export default TicketIcon;