import { ChartData, ChartOptions } from "chart.js";
import { FC, useEffect, useState } from "react";
import { Doughnut, Pie, Chart } from "react-chartjs-2";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { useAppSelector } from "../../../../../core/store/hooks";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export const BerthChart: FC = () => {
  const stats = useAppSelector(({ berth }) => berth?.stats?.chart);
  const { t } = useTranslation("berth");

  const [datas, setDatas] = useState<ChartData<"doughnut", number[], string>>();
  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
    // rotation: 1 * Math.PI,
    // circumference: 1 * Math.PI,
    rotation: 86 * Math.PI,
    circumference: 57 * Math.PI,
  };

  useEffect(() => {
    if (stats) {
      const data = {
        labels: stats.labels,
        datasets: [
          {
            data: stats.data,
            backgroundColor: stats.backgroundColor,
          },
        ],
      };
      setDatas(data);
    }
  }, [stats]);

  return (
    <Box
      className="flex items-center justify-center"
      sx={{
        backgroundColor: "#fff",
        width: "300px",
        height: "150px",
        position: "relative",
        padding: "10px",
      }}
    >
      {stats && datas ? (
        <>
          <Doughnut options={options} data={datas} />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              lineHeight: "1em",
              fontWeight: "bold",
              width: "100%",
              transform: "translateY(-75%)",
            }}
            className="flex flex-col items-center justify-center align-center"
          >
            <span className="block">{stats.percentage}%</span>{" "}
            {t("legend.graphLegend")}
          </Box>
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
