import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const getPortConfig = createSelector(
  (state: RootState) => state.ports.current,
  (port) => port?.config
);

export const getCurrentMapCenter = (areaId: string | undefined) =>
  createSelector(
    (state: RootState) => state.ports,
    (ports) =>
      typeof areaId !== "undefined" 
      && areaId !== ""
      && areaId !== "all"
      && ports!.current!.areas.find(
        (area: any) => area.id === areaId
      )
        ? [
            ports!.current!.areas.find(
              (area: any) => area.id === areaId
            )!.coordinates!.lng,
            ports!.current!.areas.find(
              (area: any) => area.id === areaId
            )!.coordinates!.lat,
          ]
        : [ports!.current!.center_longitude, ports!.current!.center_latitude]
  );
