import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";

export const getUserProfile = createAsyncThunk(
  "user/profile",
  async () => {
    return FDK.Marina.UserMarinaAPI.getUserProfile().then((res) => {
      if (res) {
        return res.data;
      }

    });
  }
);

export const getAgentsManagersList = createAsyncThunk(
  "user/list",
  async () => {
    return FDK.Marina.UserMarinaAPI.getAgentsManagersList().then((res) => {
      return res.data;
    });
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (fd: FormData) => {
    return FDK.Marina.UserMarinaAPI.updateUser(fd).then((res) => {
      return res.data;
    });
  }
);