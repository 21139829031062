import { createSlice } from "@reduxjs/toolkit";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import { postDocument } from "../actions/Attachments.actions";

export interface DocumentFromPicker {
  uri: string;
}

export interface AttachmentState {
  uploading: DocumentFromPicker[];
  uploaded: IAttachment[];
  error: DocumentFromPicker[];
}
const initialState = {
  uploading: [],
  uploaded: [],
  error: [],
} as AttachmentState;

export const Attachments = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    moveToUploaded: (state, { payload }) => {
      state.uploading = state.uploading.filter(
        (f) => f.uri !== payload.file.uri
      );
      state.uploaded.push({ ...payload.uploadedFile, uri: payload.file.uri });
    },
    removeFromUploaded: (state, { payload }) => {
      state.uploaded.filter((file) => file.id !== payload.id);
    },
    resetAttachment: (state) => {
      return { ...initialState };
    },
    addToUploading: (state, { payload }) => {
      state.uploading.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDocument.fulfilled, (state, { payload }) => {
        // state.uploading.push(payload.file)
      })
      .addCase(postDocument.rejected, (state, { payload }) => {
        // ???
        state.uploading.find((file) => file.uri === (payload as any).file.uri);
      });
  },
});

export default Attachments.reducer;
export const {
  addToUploading,
  moveToUploaded,
  removeFromUploaded,
  resetAttachment,
} = Attachments.actions;
