import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "../../../../core/store/hooks";
import { generateAreaUrl } from "../../../../_helpers/misc.helpers";

export const AreasMapButtons = () => {
  const { areaId } = useParams();
  const { t } = useTranslation("app");
  const port = useAppSelector((state) => state.ports.current);
  const navigate = useNavigate();
  return (
    <Box className="absolute top-0 left-0 w-full flex items-center justify-center space-x-2 mt-2">
      {(port as any).areas.length > 1 &&
        <Button onClick={() => navigate(generateAreaUrl("all"))}
          className={areaId === ("all" || "undefined") ? "" : "opacity-60"} color="light" variant="contained">
          {t("allAreas")}
        </Button>}
      {(port as any).areas.length > 1 &&
        (port as any).areas.map((area: any) =>
          <Button key={`area-btn-${area.id}`}
            onClick={() => navigate(generateAreaUrl(area.id))}
            className={areaId === area.id ? "" : "opacity-60"} color="light" variant="contained">
            {area.name}
          </Button>)
      }
    </Box>
  )
}