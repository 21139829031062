import { Box, Typography } from "@mui/material";

type PageTitleProps = {
    title: string,
    subtitle: string
    icon: JSX.Element;
}

export const PageTitle = (props: PageTitleProps) => {
    return (<Box className="flex p-6 gap-x-4 shadow-sm items-center">
        <Box style={{ boxShadow: "1px 1px 1px #563f53" }} className="bg-fcomain flex items-center justify-center w-12 h-12">
            {props.icon}
        </Box>
        <Box className="flex flex-col justify-center">
            <Typography component="h1" variant="h5" className="leading-4 font-bold">{props.title}</Typography>
            <Typography className="">{props.subtitle}</Typography>
        </Box>
    </Box>)
}