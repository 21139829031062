import { AlertType, useAppContext } from "../app/contexts/AppContext";

let resolveCallback: any;
function useConfirm() {

  const { alertMessage, setAlertMessage } = useAppContext();

  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (message: string) => {
    setAlertMessage!({ type: AlertType.CONFIRM, message })

    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };
  const closeConfirm = () => {
    setAlertMessage!({ type: AlertType.CLEAR, message: "" })
  };

  return { confirm, onConfirm, onCancel, alertMessage };
}

export default useConfirm;