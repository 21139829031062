import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, MouseEvent, useContext, useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppContext, useAppContext } from "../../contexts/AppContext";
import { useTheme } from "@mui/material";
import HelpIcon from "../../../assets/img/menu/HelpIcon";
import { HarborMenu, ManagementMenu } from "./DrawerMenu.element";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "fixed", // set relative to push content the the right
    whiteSpace: "nowrap",
    overflowX: "hidden",
    // backgroundColor: useTheme().palette.primary.main,
    width: useAppContext().drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      position: "fixed",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

export const DrawerElement: FC = ({ children }) => {
  const { t } = useTranslation("app");
  const { drawerOnTop, drawerOpened, toggleDrawer } = useAppContext();
  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleDrawer!();
  };
  return (
    <Drawer hideBackdrop={false} variant="permanent" open={drawerOpened}>
      <Box component="div" className="h-full flex flex-col justify-between">
        <Box>
          <Toolbar className="flex items-center justify-between">
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              {t("menu.bloc1.title")}
            </Typography>
            <IconButton
              disabled={!drawerOnTop}
              color="inherit"
              onClick={handleOnClick}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider
            style={{
              marginTop: "9px",
              marginBottom: "10px",
              borderColor: "rgb(255 255 255 / 33%)",
            }}
          />
          <List component="nav">
            <HarborMenu />
            <Toolbar className="flex justify-between items-center mt-0 md:mt-16 px-2">
              <Typography component="h1" variant="h6" color="inherit" noWrap>
                {drawerOpened && t("menu.bloc2.title")}
              </Typography>
            </Toolbar>
            <Divider
              style={{
                marginTop: "9px",
                marginBottom: "20px",
                borderColor: "rgb(255 255 255 / 33%)",
              }}
            />
            <ManagementMenu />
          </List>
        </Box>
        <Box component="div" className="bg-red-500 w-full bg-opacity-50">
          <NavLink
            className={({ isActive }) => (isActive ? "drawer-menu-active" : "")}
            to="/admin/help"
          >
            <ListItemButton>
              <HelpIcon />
              <ListItemText
                disableTypography
                primary={<div className="ml-4 font-bold text-md">{t("menu.help")}</div>}
              />
            </ListItemButton>
          </NavLink>
        </Box>
      </Box>
    </Drawer>
  );
};
