import { Badge, Box, Chip, Paper, Typography } from "@mui/material";
import { IPlugContract } from "@wattsonelements/front-fdk/dist/models/Electricity.models";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { PlugContract } from "./PlugContract.element";


type PlugContractsListProps = {
  contracts: IPlugContract[]
}
export const PlugContractsList: FC<PlugContractsListProps> = ({ contracts }) => {
  const { t } = useTranslation("electricity");

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <Box className="mx-4 mb-12">
      <Typography className="flex gap-2" variant="h5">        
        {t('detail.contract')} <Chip variant="outlined" color="secondary" label={contracts?.length} />
      </Typography>

      {
        (!contracts || contracts.length === 0) &&
        <Paper variant="outlined" className="text-center p-4 mt-2 text-fcogrey">
          {t("detail.noContract")}
        </Paper>
      }
      {
        contracts && contracts.length > 0 && 
        <Slider {...settings}>
          {
            contracts.map((contract: IPlugContract) => <div><PlugContract key={contract.id} contract={contract} /></div>)
          }
        </Slider>
      }
     
    </Box>
  )

}