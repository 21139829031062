import { IPortArea } from "@wattsonelements/front-fdk/dist/models/Port.models";
import { Geojson } from "../models/map.models";
import { MapType } from "../../_helpers/map.helper";

export const usePortService = () => {
  const generateAreasStatsGeoJson = (areas: IPortArea[], module: MapType) => {
    let tmpGeoJson: Geojson = { type: "FeatureCollection", features: [] };
    areas.map((area, i) => {
      const iconName = "port_area";
      const anchor ={ x: 10, y: 10 };
      tmpGeoJson.features.push({
        type: "Feature",
        id: area.id,
        properties: {
          rotation: 0,
          width: 20,
          icon: `${iconName}-${module}-${area.id}`,
          selectedIcon: `${iconName}-${module}-${area.id}-select`,
          anchor: anchor,
          id: area.id,
          clickable: true,
        },
        geometry: {
          type: "Point",
          coordinates: [area.coordinates!.lng, area.coordinates!.lat],
        },
      });
    });
    return tmpGeoJson;
  };
  return { generateAreasStatsGeoJson }
}

