import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";
import { ReactComponent as SVG } from './news.svg'

export const NewsIcon: FC = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <SVG />
    </SvgIcon>
  );
}

export default NewsIcon;