import { createAsyncThunk } from "@reduxjs/toolkit";
import FDK from "@wattsonelements/front-fdk";
import { IAttachment } from "@wattsonelements/front-fdk/dist/models/Attachment.models";
import { addToUploading, moveToUploaded } from "../reducers/Attachments.reducer";

export const postDocument = createAsyncThunk(
  "attachments/attachment",
  async (file: any, { dispatch }) => {
    const fd = new FormData();
    fd.append("file", file);
    dispatch(addToUploading(file));
    return FDK.Marina.TicketsModule.postAttachment(fd).then(
      (res) => {
        dispatch(moveToUploaded({ file, uploadedFile: res.data }));
        console.log("ATTACHMENT SUCCESS", res.data);
        return { file: res.data } as { file: IAttachment };
      },
      (error) => {
        return { error, file };
      }
    );
  }
);

